const CK_BUILDER_LAUNCHER_SELECTOR = '[data-ck-builder-launcher]';
const SECTION_ID = 'ck-builder';
const launchedCKBuilders = [];

document.body.addEventListener('click', async (event) => {
    const {target} = event;
    if (!target) {
        return;
    }

    const ckBuilderLauncher = target.closest(CK_BUILDER_LAUNCHER_SELECTOR);
    if (!ckBuilderLauncher) {
        return;
    }

    const url = ckBuilderLauncher.getAttribute('data-ck-builder-launcher');
    if (!url) {
        return;
    }

    event.preventDefault();

    if (launchedCKBuilders.includes(url)) {
        const e = new CustomEvent('ck-builder:open', {detail: {url}});
        document.dispatchEvent(e);
        return;
    }

    const container = document.createElement('div');
    container.setAttribute('data-ck-builder', url);
    container.innerHTML = '<div class="ck-builder__loader-wrapper"><div class="ck-builder__loader"></div></div>';
    document.body.appendChild(container);

    try {
        if (!launchedCKBuilders.length) {
            await Promise.all([
                import('@/styles/ck-builder.scss'),
                import('@/scripts/sections/ck-builder')
            ]);
        }

        const response = await fetch(`${url}?section_id=${SECTION_ID}`).then((res) => {
            return res.text();
        });

        if (!response) {
            return;
        }
        
        const doc = new DOMParser().parseFromString(response, 'text/html');
        if (!doc) {
            return;
        }

        const html = doc.querySelector('.shopify-section');
        if (!html?.innerHTML) {
            return;
        }

        container.innerHTML = html.innerHTML;

        launchedCKBuilders.push(url);
    } catch (error) {
        console.error(`Error launching CK builder: ${error}`);
    }
});
